export class Modal {

	constructor() {

		let openModals = document.getElementsByClassName('open-modal');
		Array.prototype.slice.call(openModals).forEach((openModal) => {
			openModal.addEventListener('click', this.openModal);
		});

		let closeModals = document.getElementsByClassName('close-modal');
		Array.prototype.slice.call(closeModals).forEach((closeModal) => {
			closeModal.addEventListener('click', this.closeModal.bind(this));
		});

	}
	openModal(el){

		let modalName = el.currentTarget.dataset.modal;
		let element = document.getElementsByClassName(modalName);

		bodyScrollLock.disableBodyScroll()


		element[0].classList.add('is-open');
	}
	closeModal(el){
		let target = el.currentTarget.closest('.l-modal-frame');

		bodyScrollLock.clearAllBodyScrollLocks()

		target.classList.remove('is-open');
	}

}
