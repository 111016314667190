export class Youtube {
	constructor() {
		if (!$('#player').get(0)) {
			return;
		}
		this.player = null;
		this.apiLoad();

		let openYoutubes = document.getElementsByClassName('open-youtube');
		Array.prototype.slice.call(openYoutubes).forEach((openYoutube) => {
			openYoutube.addEventListener('click', this.openPlayer.bind(this));
		});

		let closeYoutubes = document.getElementsByClassName('close-youtube');
		Array.prototype.slice.call(closeYoutubes).forEach((closeYoutube) => {
			closeYoutube.addEventListener('click', this.stopPlayer.bind(this));
		});


	}
	openPlayer (el) {//再生動画取得
		console.log(el)
		let id = el.currentTarget.dataset.youtube;
		this.player.loadVideoById(id);
	}
	stopPlayer () {
		this.player.stopVideo();
	}
	stopPlayer () {//再生停止
		this.player.stopVideo();
	}
	apiLoad(){

		window.onYouTubeIframeAPIReady = this.onApiLoad.bind(this);

		var tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		let elements = document.getElementsByClassName('open-youtube');
		let id = elements[0].dataset.youtube
	}
	onApiLoad() {
		let elements = document.getElementsByClassName('open-youtube');
        let id = elements[0].dataset.youtube
        this.player = new YT.Player('player', {
			height: '360',
			width: '640',
			videoId: id,
		});
    }

}

