import Vivus from "vivus";

export class PageTop {
	constructor() {
		if (!$('.page-top').get(0)) {
			return; 
		}
		// const elems = $("img");
		const elems = document.querySelectorAll('image');
		this.setLoadImagesCallback(elems,()=> this.loading());
		this.slick()
		// this.parallaxAnimation()
	}
	/************************
	画像を読み込むまで待機する処理
	************************/
	setLoadImagesCallback(elems, callback) {
		let count = 0;
		for (let i = 0; i < elems.length; ++i) {
			elems[i].addEventListener('load',() => {
				++count;
				if (count == elems.length) callback();
			})

			elems[i].setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', elems[i].dataset.src);
		}
	}

	/************************
	ローディング用
	************************/
	async loading(){
		await this.svgTitleAnimation()
		// await this.removeLoading();
		// $('#opening').remove()
		this.keyvisual()
	}

	removeLoading () {
		return new Promise(function(resolve){
			$('#opening').animate({
				opacity:0
			},2000,resolve);
		});
	}


	/************************
	キービジュアル用
	************************/

	svgTitleAnimation(){
		return new Promise(function(resolve) {
			$("#opening").addClass('is-active');
			$(".kv-logo").addClass('active');
			resolve()
		})
	}

	//キービジュアルの全体処理
	//この関数を実行するとキービジュアルの処理が開始する
	keyvisual() {
		
		let timer;
		const  myAsyncFunction = async() => {
			clearTimeout(timer);
			
			$(".kv").addClass('clipped');
			
			await this.delay(0.8);
			
			await this.changePanel();
		
			$(".kv").removeClass('clipped');
			timer = setTimeout(myAsyncFunction,6000);
		}
		setTimeout(myAsyncFunction,5000);
	}

	//引数のnミリ秒後にresolveを返すプロミス処理
	delay (n) {
		return new Promise(function(resolve){
			setTimeout(resolve,n*1000);
		});
	}

	//キービジュアルの画像を入れ替えるプロミス処理
	changePanel () {
		return new Promise(function(resolve){
			let kv__panels = [];
			$('.kv__panel').each((i,val)=>{
				if(window.innerWidth > 767) {
					kv__panels.push($(val).find('image.pc'));
				} else {
					kv__panels.push($(val).find('image.sp'));
				}
				
			})

			kv__panels.forEach((element,index) => {
				if(window.innerWidth > 767) {
					$(element.slice(-1)[0]).clone().prependTo($('.kv__panel').eq(index).find('svg.pc g').get(0));
				} else {
					$(element.slice(-1)[0]).clone().prependTo($('.kv__panel').eq(index).find('svg.sp g').get(0));
				}
				$(element.slice(-1)[0]).remove();
			});
			resolve();
		});
	}

	/************************
	  実績紹介のカルーセル用
	************************/
	slick () {
		//スライダーカウント用
		const sliderCount = (slick,currentSlide)=> {
			const i = (currentSlide ? currentSlide : 0) + 1;
			$('.products__sliderNum .currentNum').text(i);
			$('.products__sliderNum .allNum').text(slick.slideCount);
		}

		//表示名切り替え用
		const changeDiscriptions = () => {
			const name = $('.slick-current').data('name');
			const prefecture = $('.slick-current').data('prefecture');
			$('.products__sliderTitle').text(name);
			$('.products__prefecture').text(prefecture);
		}

		$('.js_slider').on('init reInit afterChange',( event, slick, currentSlide) => {
			sliderCount(slick,currentSlide);
			changeDiscriptions();
		});

		$('.js_slider').slick({
			slidesToShow: 1,
			variableWidth: true,
			prevArrow: '<span class="prev-arrow"></span>',
			nextArrow: '<span class="next-arrow"></span>',
			appendArrows: $('.products__sliderTools'),
			responsive: [{
				breakpoint: 767,
				settings: {
					appendArrows: $('.js_slider'),
				}
			  },
			]
		});
	}

	/************************
	パララックスアニメーション
	************************/
	parallaxAnimation(){
		const controller = new ScrollMagic.Controller(),
			  windowHeight = window.innerHeight - $('header').innerHeight(),
			  $sec = $('.js-parallax');
			  
		$sec.each((i,el)=>{
			const $parallax = $(el).find(".parallax"),
			height = $sec.innerHeight();

			const scene = new ScrollMagic.Scene({
				triggerElement: el,
				triggerHook: 'onEnter',
				duration: height + windowHeight
			});

			$parallax.each((i,el)=>{
				const parallaxOffset = $(el).data('parallax');
				scene.on("progress", function (prog) {
					var p = prog.progress;
					$parallax.css({
						'transform': 'translate3d(0,'+ -(p * parallaxOffset) +'px ,0)'
					});
				}).addTo(controller);
			})
		})
	}
}

