
export class CommonSlider {
    constructor (controller) {
		this.controller = controller
		this.slick();
		this.swiperThumb();
    }
    slick () {
		//スライダーカウント用
		const sliderCount = (slick,currentSlide)=> {
			const i = (currentSlide ? currentSlide : 0) + 1;
			$('.common__sliderNum .currentNum').text(i);
			$('.common__sliderNum .allNum').text(slick.slideCount);
		}

		//表示名切り替え用
		const changeDiscriptions = () => {
			if($(".common__sliderTitle").get(0)) {
				const name = $('.slick-current').data('name');
				$('.common__sliderTitle').text(name);
			}
		}

		$('.commonSlider').on('init reInit afterChange',( event, slick, currentSlide) => {
			sliderCount(slick,currentSlide);
			changeDiscriptions();
		});
		$('.commonSlider').each(function() {
			
			$(this).slick({
				slidesToShow: 1,
				variableWidth: false,
				prevArrow: '<span class="prev-arrow"></span>',
				nextArrow: '<span class="next-arrow"></span>',
				appendArrows: $(this).next('.common__sliderTools'),
				responsive: [{
					breakpoint: 767,
					settings: {
						appendArrows: $(this),
					}
				  },
				]
			});
		})
	}
	swiperThumb() {
		if(!$('.slider-thumbnail').get(0)) return;

		// let width = $('.slider-thumbnail').width();
		// $('.slider-thumbnail').css('wdith',width);

		//サムネイル
		let sliderThumbnail = new Swiper('.slider-thumbnail', {
			slidesPerView: "auto",
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
		});
		//スライダー
		let slider = new Swiper('.main-slider', {
			navigation: {
				nextEl: '.main-slider-wrapper .swiper-next',
				prevEl: '.main-slider-wrapper .swiper-prev',
			},
			thumbs: {
				swiper: sliderThumbnail
			},
		});
	}
	
	
}
