import { Controller } from './common/modules/_controller.js';
import { SmoothScroll } from './common/modules/_SmoothScroll.js';
import { Modal } from './common/modules/_modal.js';
import { Youtube } from './common/modules/_youtube.js';
import { Accordion } from './common/modules/_accordion.js';
import { CommonSlider } from './common/modules/_commonSlider';



import  { GlobalHeader } from './common/_GlobalHeader';

import  { PageTop } from './common/pages/_PageTop.js';
import  { Company } from './common/pages/_Company.js';
import { Work } from './common/pages/_Work.js';
import { WorkDetail } from './common/pages/_WorksDetail.js';
import { Technology } from "./common/pages/_Technology.js";
import { TechnologyDetail } from './common/pages/_TechnologyDetail.js';
import { News } from './common/pages/_news.js';
import { Itsukushima } from './common/pages/_itsukushima';

const controller = new Controller();
const smoothScroll =  new SmoothScroll('[data-anchor]', 767);
smoothScroll.offsetPC = 100;
smoothScroll.offsetSP = 70;


const modal = new Modal();
const youtube = new Youtube();
const accordion = new Accordion();
const commonSlider = new CommonSlider(controller);
const globalHeader = new GlobalHeader(controller);


const pagetop = new PageTop(controller);

const company = new Company()
const work = new Work();
const workDetail = new WorkDetail();
const technology = new Technology(controller);
const technologyDetail = new TechnologyDetail(controller);
const news = new News();
const itsukushima = new Itsukushima(controller);

document.body.oncontextmenu = function () {return false;}


// window.addEventListener('resize:SP', function(){
// 	console.log(controller.device);
// },false)