export class Accordion {

    constructor() {
        let accordion = document.getElementsByClassName('accordion');

        Array.prototype.slice.call(accordion).forEach(element => {

            let text = element.getElementsByClassName('accordion__main');
            let textHeight = text[0].scrollHeight;
            text[0].style.setProperty('--text-height', textHeight+'px');


            window.addEventListener('resize', () => {
                let textHeight = text[0].scrollHeight;
                text[0].style.setProperty('--text-height', textHeight+'px');
            });

            let header = element.getElementsByClassName("accordion__header");
            header[0].addEventListener('click', this.toggle);

        });
    }

    toggle(el) {
        if(el.currentTarget.parentNode.classList.contains('open')) { 
            el.currentTarget.parentNode.classList.remove('open');
            el.currentTarget.parentNode.classList.add('close');
            
        }  else {
            el.currentTarget.parentNode.classList.remove('close');
            el.currentTarget.parentNode.classList.add('open');
        }
    }
    
}