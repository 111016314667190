
export class Itsukushima {
	constructor (Controller) {
		const pageCheckElement = document.getElementsByClassName('itsukushima-common')
		if(pageCheckElement.length == 0) return;

		if(document.querySelector('.sidebar-toggle')) {
			document.querySelector('.sidebar-toggle').addEventListener('click', this.sidebarToggle)
			window.addEventListener("DOMContentLoaded",this.checkSidebarOpen);
		}

		if(document.querySelectorAll('.project__item')) {
			document.querySelectorAll('.project__item').forEach((el) => {
				el.addEventListener("mouseenter",this.showMapPin)
			})
			document.querySelectorAll('.project__item').forEach((el) => {
				el.addEventListener("mouseleave",this.hideMapPin)
			})
			document.querySelectorAll('.pin').forEach((el) => {
				el.addEventListener("mouseenter",this.addClassPanel)
			})
			document.querySelectorAll('.pin').forEach((el) => {
				el.addEventListener("mouseleave",this.removeClassPanel)
			})
		}
		if(document.getElementsByClassName('itsukushima-sp').length) {
			console.log(Controller.device)
			if(Controller.device != "SP") return;
			window.addEventListener("DOMContentLoaded", this.spOpenAccordion)
		}

	}
	sidebarToggle(el) {
		el.currentTarget.classList.toggle('is-active');
		document.querySelector('.sidebar-accordion').classList.toggle("is-active");
	}
	showMapPin(el) {
		const className = el.currentTarget.dataset.project;
		document.getElementsByClassName(className)[0].classList.add('is-active');
	}
	hideMapPin(el) {
		const className = el.currentTarget.dataset.project;
		document.getElementsByClassName(className)[0].classList.remove('is-active');
	}
	addClassPanel(el) {
		const name = el.currentTarget.dataset.panel;
		document.querySelector(`.project__item[data-project="${name}"]`).classList.add('is-active');
	}
	removeClassPanel(el) {
		const name = el.currentTarget.dataset.panel;
		document.querySelector(`.project__item[data-project="${name}"]`).classList.remove('is-active');
	}
	spOpenAccordion() {
		document.querySelector('.sidebar-toggle').classList.toggle("is-active");
		document.querySelector('.sidebar-accordion').classList.toggle("is-active");
	}
}
