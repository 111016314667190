export class SmoothScroll {
	constructor (selector, breakpoint) {
		this.breakpoint = breakpoint;
		this.offsetPC = 100;
		this.offsetSP = 30;

		const anchors = document.querySelectorAll(selector);
		Array.prototype.slice.call(anchors).forEach((anchor) => {
			anchor.addEventListener('click', this.anchorClick.bind(this));
		})

	}
	anchorClick(e) {
		e.preventDefault();
		let element = e.currentTarget;
		this.scroll(this.getTop(element.getAttribute('href')));
	}
	getTop(selector) {

		if(!selector) return;
		let top = 0;
		let id = selector.split('#');
		let element = document.getElementById(id[1]);

        if(element) {
			let rect = element.getBoundingClientRect();
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			top = rect.top + scrollTop;
        }
        return top;
    }
	scroll(scrollTop) {
        let offset = window.matchMedia('(max-width:' + this.breakpoint + 'px)').matches ? this.offsetSP : this.offsetPC;
        $('html,body').stop().animate({
            scrollTop : Math.min(scrollTop - offset, $('body').height() - $(window).height()
            )
        });
    }
}

