
export class Controller {
	constructor() {
		this.device = null;
		this.mediaQuery();
		this.iosZoom();
		this.defaultMagic();
	}
	mediaQuery() {
		const resizeResponsive = new CustomEvent('resize:responsive');
		const resizeSP = new CustomEvent('resize:SP');
		const resizePC = new CustomEvent('resize:PC');
		const mediaQueryList = window.matchMedia("(max-width:767px)");
		const listener = (event) => {
			if (event.matches) {
				this.device = 'SP';
				window.dispatchEvent(resizeSP);
				window.dispatchEvent(resizeResponsive);				
			} else {
				this.device = 'PC';
				window.dispatchEvent(resizePC);
				window.dispatchEvent(resizeResponsive);

			}
		}
		//iosだとaddEventListenerでエラーになる。
		mediaQueryList.addEventListener("change", listener);
		listener(mediaQueryList);

		

		// mediaQueryList.addListener(listener)
	}
	iosZoom() {
		const ua = navigator.userAgent.toLowerCase();
		let isiOS = (ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1);
		if(isiOS) {
			let viewport = document.querySelector('meta[name="viewport"]');
			if(viewport) {
				let viewportContent = viewport.getAttribute('content');
				viewport.setAttribute('content', viewportContent + ', user-scalable=no');
			}
		}
	}
	defaultMagic(){
		if (typeof ScrollMagic != 'undefined') {
            let section = document.querySelectorAll('.js-anime:not(.is-active)');
            let controller = new ScrollMagic.Controller();
            if (section.length != 0) {
                Array.prototype.slice.call(section).forEach(function (sec, index) {

                    let scene = new ScrollMagic.Scene({
                        triggerElement: sec,
                        triggerHook: sec.dataset.hook || 'onEnter',
                        offset: sec.dataset.offset || 100,
                    })
                    // .addIndicators( {name: "scroll"})
                    .addTo(controller);
                    scene.on('enter', function (event) {
                        sec.classList.add('is-active');
                    });
                });
            }
        }
	}
}

// module.exports = Controller;