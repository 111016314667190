
export class News {
    constructor(){
        if(!$('.news-common').get(0)) return;
        this.checkTag()
        $('.news__tab-item').on('click', this.sortNews.bind(this));
    }
    checkTag() {
        let elements = document.querySelectorAll('.news__block');
        let arr = [];
        [...elements].forEach(function(element){
           arr.push(element.dataset.target);
        });
        const tags = new Set(arr);
        [...tags].forEach(function(tag){
            let abldeTag = document.querySelector('.news__tab-item[data-tag="'+ tag + '"]');
            console.log(abldeTag)
            abldeTag.classList.add('is-abled');
        })
    }
    sortNews(el) {
        $('.news__tab-item').removeClass('is-active')
        $(el.currentTarget).addClass('is-active')
        let target = $(el.currentTarget).data('tag');
        if(target != 'all') {
            let targetEl = $('.news__block[data-target="'+ target +'"]');
            $('.news__block').addClass('is-hide');
            $(targetEl).removeClass('is-hide');
        } else {
            $('.news__block').removeClass('is-hide');
        }
        
    }
}