export class GlobalHeader {

	constructor (controller) {
		let hamburger = document.getElementById('hamburger');
		hamburger.addEventListener('click',this.toggleHamburgerMenu);

		let openAccordionMenus = $('.header__lists').find('.overlay');
		$(openAccordionMenus).on('click',this.openAccordionMenus);

		//PC切り替え時のスタイル解除
		window.addEventListener('resize:PC',this.removeAllstyle,false)
	}
	toggleHamburgerMenu(){
		let $target = $(this).parent('.header__wrapper');
		$target.toggleClass("isOpen");
		if($target.hasClass('isOpen')){
			bodyScrollLock.disableBodyScroll('.header__nav');
		}else{
			bodyScrollLock.clearAllBodyScrollLocks();
		}
	}
	openAccordionMenus(e){
		if($(window).innerWidth() > 767) return;
		if(!$(e.target).parents('.header__md').get(0)) {
			(e.preventDefault) ? e.preventDefault():e.returnValue=false;
			$(this).find('.header__md').stop().slideToggle();
			$(this).children('a').stop().toggleClass('active');
			return;
		};
	}
	removeAllstyle(){
		bodyScrollLock.clearAllBodyScrollLocks();
		$('.header__wrapper').removeClass('isOpen');
		$('.header__lists').find('.overlay').children('a').removeClass('active')
		$('.header__lists').find('.overlay').children('.header__md').removeAttr('style');
	}
}