export class TechnologyDetail {
	constructor(controller) {
		if(!$('.tradition-detail').get(0)) return;
        this.relationSlider();
	}
    relationSlider() {
		if(!$('.relationSlider').get(0)) return;
		this.slider = $(".relationSlider").slick({
			slidesToShow: 3,
			variableWidth: true,
			prevArrow: '<span class="prev-arrow"></span>',
			nextArrow: '<span class="next-arrow"></span>',
			appendArrows: $(".relationSlider").next('.relation__sliderTools'),
			infinite: true,
			responsive: [{
				breakpoint: 767,
				settings: {
					freeModae: true,
				}
			  },
			]
		});

        
        let index = document.querySelector('.relationSlider').dataset.index;
		if(index) {
			this.slider.slick('slickGoTo',parseInt(index, 10))
		}
	}
}
