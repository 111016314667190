import { SmoothScroll } from '../modules/_SmoothScroll.js';
export class Company {
    constructor (){
		const pageCheckElement = document.getElementsByClassName('page-company')
		if(pageCheckElement.length == 0) return;
		this.timeLineMove();

		const anchors = document.querySelectorAll("[data-anchorYear]");
		Array.prototype.slice.call(anchors).forEach((anchor) => {
			anchor.addEventListener('click', this.anchorClick.bind(this));
		})
    }
	timeLineMove() {
		
		
		let elements = document.getElementsByClassName("history-year");
		let controller = new ScrollMagic.Controller();
		

		Array.prototype.slice.call(elements).forEach(function (element, index) {

			let scene = new ScrollMagic.Scene({
				triggerElement: element,
				triggerHook: element.dataset.hook || 'onCenter',
				offset: element.dataset.offset || 0,
				duration: $(element).outerHeight()
			})
			// .addIndicators( {name: "scroll"})
			.addTo(controller);
			scene.on('enter', function (event) {
				
				$('.year-item').removeClass('is-active');

				const year = element.dataset.year;
				const target = document.querySelectorAll('[data-target="'+year+'"]');
				$(target).addClass('is-active');
				
			});
			scene.on('update', function (event) {
				scene.duration($(element).outerHeight())
			});
		});
	}
	anchorClick(e) {
		e.preventDefault();
		let element = e.currentTarget;
		
		this.scroll(this.getTop(element.getAttribute('href')));
	}
	getTop(selector) {

		if(!selector) return;

		
		let top = 0;
		let id = selector.split('#');
		let element = document.getElementById(id[1]);

        if(element) {
			let rect = element.getBoundingClientRect();
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			top = rect.top + scrollTop;
        }
		console.log(top)
        return top;
    }
	scroll(scrollTop) {
        let offset = $(window).height() / 2 - 10;

        $('html,body').stop().animate({
            scrollTop : scrollTop - offset
        });
    }
}