
export class WorkDetail {
    constructor() {
        if(!$('.works__detail').get(0)) return;
        $('.anchor-link li').on('click',this.anchorToggle.bind(this))
    }
    anchorToggle(el) {
        $('.anchor-link li').removeClass('is-active');
        $(el.currentTarget).addClass('is-active')
    }
}