
export class Work {
    constructor() {
        if(!$('.works-top').get(0)) return;


        $('input:not(.diabledHiddenToggle)').on('change',this.createQuery.bind(this));
        $('.allToggle').on('click', this.allToggle.bind(this));
        $('.checked-text').on('click',this.yearToggle.bind(this));
        $('.year-select-list input').on('change',this.changeYear.bind(this));
        $('.diabledHiddenToggle').on('change',this.diabledHiddenToggle.bind(this));

        $('.all-clear').on('click', this.allClear.bind(this));
        $('.search-open').on('click', this.searchOpen.bind(this));
        $('.search-close').on('click', this.searchClose.bind(this));

        this.init()
    }
    init() {
        this.api = $('#sidebar form').data('api');

        let url = new URL(window.location.href);
        let params = url.searchParams;
        let values = params.values();
        console.log(localStorage.getItem('filter'))
        if(params.has('works_category')) {
            for(let value of values) {
                let parents = $('[data-name="' + value +'"]').parents('.l-checkbox');
                let target = parents.find('input[type="checkbox"]');
                $(target).click();
            }
        }
        else if(localStorage.getItem('filter') != null && localStorage.getItem('filter') != 'false'){
            this.getLocalStorage();
        }
        else {
            this.createQuery()
        }
    }

    setLocalStorage(arr) {
        localStorage.setItem('filter', arr);
    }
    getLocalStorage(){
        if(localStorage.getItem('filter') == null) return;
        let saveDatas = localStorage.getItem('filter');
        let newArr = JSON.parse(saveDatas)
        newArr.forEach(function(data){
            $('[value="'+ data +'"]').prop('checked', true);
        });

		this.createQuery();
    }
    clearLocalStorage() {
        localStorage.clear();
    }
    getAPI(params = undefined) {
        axios.get(this.api, {params})
        .then((res) => {
            const parent = document.querySelector('.works-contents');
            while(parent.firstChild){
                parent.removeChild(parent.firstChild);

            }

            this.setCount(res.data.count)
            this.setPanel(res.data.html)
            this.setQuery(res.data.html)
            this.setAllCount(res.data.publish);
        })
        .catch(err => {
            console.log("err:", err);
        });
    }
    //checkされたものを取得
    createQuery() {
        if(!$('.diabledHiddenToggle').prop('checked')) {
            $('.disabledInput').prop('disabled', true);
        }
        const formElement = document.getElementById('sidebar-form');
        const formData = new FormData(formElement)
        const params = new URLSearchParams(formData);
        let saveData = []
        for(let param of params.values()) {
            saveData.push(param)
        }
        if(saveData.length === 0) {
            this.setLocalStorage('false')
        } else {
            this.setLocalStorage(JSON.stringify(saveData))
        }
        this.getAPI(params);
        this.pageTop()
    }
    // releaseDiabled() {
    //     $(".disabledInput").prop('disabled',true);
    //     console.log('test')
    // }
    diabledHiddenToggle(el) {
        let label = $(el.currentTarget).parents('label');
        let disabledInput = label.find(".disabledInput");
        disabledInput.prop('disabled',false);

        const formElement = document.getElementById('sidebar-form');
        const params = new URLSearchParams(new FormData(formElement));
        let saveData = []
        for(let param of params.values()) {
            saveData.push(param)
        }
        if(saveData.length === 0) {
            this.setLocalStorage('false')
        } else {
            this.setLocalStorage(JSON.stringify(saveData))
        }
        this.getAPI(params)
        this.pageTop()
    }
    //記事表示件数をセット
    setAllCount(data) {

        let od = document.querySelectorAll('.found-post');
        od.forEach(function(el){
            let odometer = new Odometer({
                el: el,
                value: 0,
                theme: 'minimal'
            });
            odometer.update(data)
        })

    }
    //絞り込み条件設定
    setQuery(datas){

        let queryText = [];

        let cat_query = []

        $('.works_category input').each(function(){
            if($(this).prop('checked')) {
                let text = $(this).data('display');
                if(text != undefined) {
                    cat_query.push(text);
                }
            }
        })
        if(cat_query.length > 0) {
            cat_query = '事業：' + cat_query.join(',');
            queryText.push(cat_query)
        }


        let pre_query = []

        $('.prefecture input').each(function(){
            if($(this).prop('checked')) {
                let text = $(this).data('display');
                if(text != undefined) {
                    pre_query.push(text);
                }
            }
        })
        if(pre_query.length > 0) {
            pre_query = '地域：' + pre_query.join(',')
            queryText.push(pre_query)
        }


        let year_query = []

        $('.completion_year input:not([type="hidden"])').each(function(){
            if($(this).prop('checked')) {
                let text = $(this).data('display');
                if(text != 'all') {
                    year_query.push(text);
                } else {
                    $('.year-radio input:not([type="hidden"]').each(function(){
                        let txt = $(this).data('display');
                        year_query.push(txt);
                    })
                }
            }
        });

        if(year_query.length > 0) {
            year_query = '年代：' + year_query.join(',');
            queryText.push(year_query)
        }

        $('.query').text(queryText.join(' / '));
        this.resultToggle();
    }
    //記事のパネル生成
    setPanel(datas) {
        console.log(datas)
        let html = '';
        datas.forEach(function(data){
            html += `<li class="works-item">
                <a class="link" href="${data.link}">
                    <div class="thumb">
                        <img src="${data.thumb}">
                    </div>
                    <div class="contents">
                        <h3 class="works-title">${data.title}</h3>
                        <ul class="tags">
                            <li class="architecture">${data.category}</li>
                            ${data.template == 'construction' ? `<li class="construction">設計施工</li>` : ''}
                        </ul>
                        <div class="info">
                            <p class="prefecture">${data.prefecture}</p>
                            <p class="year">${data.year}<span>年</span></p>
                        </div>
                    </div>
                </a>
            </li>
            `
        });
        let element = document.querySelector('.works-contents');
        element.insertAdjacentHTML('afterbegin', html);

    }
    //カテゴリーの件数をセット
    setCount(datas) {
        Object.keys(datas.works_category).forEach(function(key) {
            let el = document.querySelectorAll('[data-name="'+ key +'"]');
            for(let i = 0;el.length > i;i++) {
                $(el[i]).text(datas.works_category[key]);
            }

            let parents = $('[data-name="'+ key +'"]').parents('.l-checkbox');
            let input = parents.find('input');

            if(datas.works_category[key] == 0) {
                input.prop('checked', false);
                input.prop('disabled', true);
            } else {
                input.prop('disabled', false);
            }
        });

        let sumPrefectur = 0;

        Object.keys(datas.prefecture).forEach(function(key) {
            let el = document.querySelector('[data-name="'+ key +'"]');
            $(el).text(datas.prefecture[key]);

            let parents = $('[data-name="'+ key +'"]').parents('.l-checkbox');
            let input = parents.find('input');
            if(datas.prefecture[key] == 0) {
                input.prop('checked', false);
                input.prop('disabled', true);

            } else {
                input.prop('disabled', false);
            }

            sumPrefectur += datas.prefecture[key];
        });

        let prefectureAll = document.querySelector('[data-name="prefecture"]');
        $(prefectureAll).text(sumPrefectur);


        let sumYear = 0;
        let sumCompletion_year = 0;

        Object.keys(datas.completion_year).forEach(function(key) {
            $('[data-name="'+ key +'"]').attr('data-count',datas.completion_year[key])
            if(key > 2010) {
                let el = document.querySelectorAll('[data-name="'+ key +'"]');
                for(let i = 0; el.length > i;i++) {
                    $(el[i]).text(datas.completion_year[key]);
                }
                let parents = $('[data-name="'+ key +'"]').parents('label');
                let input = parents.find('input');
                if(datas.completion_year[key] == 0) {
                    input.prop('disabled', true);
                } else {
                    input.prop('disabled', false);
                }
            }

            if(key <= 2010) {
                sumYear += datas.completion_year[key];
            }

            sumCompletion_year += datas.completion_year[key];
        });

        if(sumYear == 0) {
            $('.diabledHiddenToggle').prop('disabled', true);
        } else {
            $('.diabledHiddenToggle').prop('disabled', false);
        }

        let extraEl = document.querySelectorAll('[data-name="~2010"]');
        for(let i = 0;extraEl.length > i;i++) {
            $(extraEl[i]).text(sumYear);
        }


        $('[data-name="completion_year"]').attr('data-count',sumCompletion_year)
        let completion_yearEl = document.querySelectorAll('[data-name="completion_year"]');

        for(let i = 0;completion_yearEl.length > i;i++) {
            $(completion_yearEl[i]).text(sumCompletion_year);
        }

        this.checkAll()
    }
    //カテゴリ-ごとにcheckが１個外れたら、全てを外す
    checkAll() {
        $('.architecture input:not(:disabled)').each(function(){
            if(!$(this).prop('checked')) {
                $('.architecture .allToggle').prop('checked', false);
            }
        })
        $('.civil input:not(:disabled)').each(function(){
            if(!$(this).prop('checked')) {
                $('.civil .allToggle').prop('checked', false);
            }
        })
        $('.prefecture-list input:not(:disabled)').each(function(){
            if(!$(this).prop('checked')) {
                $('.prefecture-list .allToggle').prop('checked', false);
            }
        })
    }
    //すべてボタンのトグル
    allToggle(el) {
        let name = null;
        if($(el.currentTarget).prop('checked')) {
           name = $(el.currentTarget).data('name');
           $('[name="' + name + '"]:not(:disabled)').prop('checked',true);

        } else {
            name = $(el.currentTarget).data('name');
            $('[name="' + name + '"]:not(:disabled)').prop('checked',false);
        }
    }

    yearToggle(el) {
        $('.year-list-wrapper').toggleClass("is-active");
    }
    changeYear(el) {
        $('.year-list-wrapper').toggleClass("is-active");
        const value = $('.year-select-list input:checked').attr('data-value');
        const count = $('.year-select-list input:checked ~ .year-text .year-count').attr("data-count");
        const name =  $('.year-select-list input:checked ~ .year-text .year-count').attr("data-name");
        $('.year-list-wrapper .checked-name').text(value)
        $('.year-list-wrapper .checked-count').text(count)
        $('.year-list-wrapper .checked-count').attr('data-name',name)
    }
    resultToggle() {
        let flag = false
        $('input').each(function(){
            if($(this).prop('checked')) {
                flag = true;
                return false;
            }
        })
        if(flag) {
            $('.selected').addClass('is-active');
            $('.all-btn').addClass('is-active');
        } else {
            $('.selected').removeClass('is-active');
            $('.all-btn').removeClass('is-active');
        }
    }
    allClear() {
        $('input').prop('checked', false);
        this.clearLocalStorage()
        this.getAPI();
    }
    pageTop() {
        if(localStorage.getItem('filter') == null || localStorage.getItem('filter') == 'false') return;
        let offset = $('.main-contents').offset().top - $('.global-header').height() - 10;
        $('body,html').animate({scrollTop: offset},300,'swing');
    }
    searchOpen() {
        $('#sidebar-form').addClass('is-active');
        $('.overlay').addClass('is-active');

    }
    searchClose() {
        $('#sidebar-form').removeClass('is-active');
        $('.overlay').removeClass('is-active');
    }
}