export class Technology {
	constructor(controller) {
		if(!$('.page-technology').get(0)) return
		this.controller = controller
		$(window).on("load", this.slick.bind(this));

		window.addEventListener("resize", () => {
			let windowWidth = window.innerWidth;
			if (windowWidth <= 767) {
				this.slick();
				// this.scrollIcon();
				this.leftScroll();
			}
			else {
				$(".technologySlider").slick("unslick");
			}
		});

	
		this.leftScroll();
	}
	slick() {
		if ($(window).innerWidth() > 767) return;

		$(".technologySlider").each(function () {
			// スライダーカウント
			$(this).on("init reInit afterChange",(event, slick, currentSlide) => {
				const i = (currentSlide ? currentSlide : 0) + 1;
				$(this).parents(".common__sliderWrapper").next(".common__sliderTools").find(".currentNum").text(i);
				$(this).parents(".common__sliderWrapper").next(".common__sliderTools").find(".allNum").text(slick.slideCount);
			});

			$(this).slick({
				slidesToShow: 1,
				variableWidth: false,
				prevArrow: '<span class="prev-arrow"></span>',
				nextArrow: '<span class="next-arrow"></span>',
				appendArrows: $(this).next(".common__sliderTools"),
				responsive: [
				{
					breakpoint: 767,
					settings: {
					appendArrows: $(this),
					},
				},
				],
			});
		});
	}
	scrollIcon() {
		// //SP時画像横スクロールアイコン
		// $(".scroll-icon").on("touchstart", onTouchStart);
		// $(".scroll-icon").on("touchmove", onTouchMove);
		// $(".scroll-icon").on("touchend", onTouchEnd);


		// var direction, position;
		// //スワイプ開始時の横方向の座標を格納
		// function onTouchStart(event) {
		// position = getPosition(event);
		// direction = "";
		// }
		// //スワイプの方向（left／right）を取得
		// function onTouchMove(event) {
		// if (position - getPosition(event) > 20) {
		// 	direction = "left";
		// } else if (position - getPosition(event) < -20) {
		// 	direction = "right";
		// }
		// }
		// //スワイプしたらアイコンを消す
		// function onTouchEnd(event) {
		// if (direction == "left") {
		// 	$(this).find(".icon").fadeOut();
		// }
		// }
		// //横方向の座標を取得
		// function getPosition(event) {
		// return event.originalEvent.touches[0].pageX;
		// }
	}
	leftScroll() {
		if(!$('.taishin').get(0)) return;
		if(this.controller.device != "PC") {
			console.log('a')
			$('.scroll-icon').on('scroll', function(){
				let scroll_x = $('.scroll-icon').scrollLeft()
				if(scroll_x > 10 ) {
					$('.scroll-icon .icon').fadeOut();
				}
				
			})
		}
	}
}
